import section1 from './section-1@2x.png';
import section2 from './section-2@2x.png';
import section3 from './section-3@2x.png';
import section4 from './section-4.gif';
import section6 from './section-6@2x.png';
import section7_1 from './section-7-1@2x.png';
import section7_2 from './section-7-2@2x.png';
import section7_3 from './section-7-3@2x.png';
import section7Roll1 from './section-7-roll-1@2x.png';
import section7Roll2 from './section-7-roll-2@2x.png';
import section8 from './section-8@2x.png';
import section9 from './section-9@2x.png';
import section9Form from './section-9-form@2x.png';
import section9Circle from './section-9-circle@2x.png';
import section10 from './section-10@2x.png';
import privacyDesc from './privacy-desc@2x.png';
import privacyDescBottom from './privacy-desc-bottom@2x.png';
import btnSubmit1 from './btn-submit-1@2x.png';
import btnSubmit2 from './btn-submit-2@2x.png';
import labelName from './label-name@2x.png';
import labelCare from './label-care@2x.png';
import labelPhone from './label-phone@2x.png';
import labelTime from './label-time@2x.png';
import labelNameBottom from './label-name-bottom@2x.png';
import labelCareBottom from './label-care-bottom@2x.png';
import labelPhoneBottom from './label-phone-bottom@2x.png';
import labelTimeBottom from './label-time-bottom@2x.png';
import feeSchedule from './fee-schedule.png'
import styled, { css } from "styled-components";

const getResponsive = (key, value) => {
  return css`
    ${key}: calc(100vw * ${value} / 990) !important;

    @media screen and (max-width: 375px) {
      ${key}: calc(375px * ${value} / 990) !important;
    }
    @media screen and (min-width: 989px) {
      ${key}: ${value}px !important;
    }
  `;
}

const SliderItem1 = styled.img.attrs({
  src: section7Roll1,
})`
  width: 100%;
  ${getResponsive('height', 947)};
`

const SliderItem2 = styled.img.attrs({
  src: section7Roll2,
})`
  ${getResponsive('width', 934)};
  ${getResponsive('height', 948)};
`

export default {
  section1,
  section2,
  section3,
  section4,
  section6,
  section7_1,
  section7_2,
  section7_3,
  section7SliderItems: [
    SliderItem1,
    SliderItem2
  ],
  section8,
  section9,
  section9Circle,
  section10,
  privacyDesc,
  btnSubmit1,
  btnSubmit2,
  labelName,
  labelCare,
  labelTime,
  labelPhone,
  labelNameBottom,
  labelCareBottom,
  labelPhoneBottom,
  labelTimeBottom,
  privacyDescBottom,
  section9Form,
  feeSchedule,
};
