import React from "react";
import ReactDOM from "react-dom";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from 'beautiful-react-hooks';
import DesktopContainer from "./desktop";
import MobileContainer from "./mobile";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

const App = () => {
  const isMobileSize = useMediaQuery('(max-width: 990px)');
  return (
    <>
      {(isMobile || isMobileSize) ? <MobileContainer /> : <DesktopContainer />}
    </>
  )
}

ReactDOM.render(
  <App />
  , document.getElementById('app'));
