import btnFloating from './btn-floating@2x.png';
import btnArrowLeft from './btn-arrow-left@2x.png';
import btnArrowRight from './btn-arrow-right@2x.png';
import logo1 from './logo-1@2x.png';
import logo2 from './logo-2@2x.png';
import logo3 from './logo-3@2x.png';
import logo4 from './logo-4@2x.png';
import logo5 from './logo-5@2x.png';
import logo6 from './logo-6@2x.png';
import logo7 from './logo-7@2x.png';
import logo8 from './logo-8@2x.png';
import logo9 from './logo-9@2x.png';
import logo10 from './logo-10@2x.png';
import logo11 from './logo-11@2x.png';
import logo12 from './logo-12@2x.png';
import logo13 from './logo-13@2x.png';
import logo14 from './logo-14@2x.png';
import section8Btn1 from './section-8-btn-1@2x.png';
import section8Btn2 from './section-8-btn-2@2x.png';
import section8Btn3 from './section-8-btn-3@2x.png';
import section10Btn1 from './section-10-btn-1@2x.png';
import section10Btn2 from './section-10-btn-2@2x.png';
import styled, { css } from "styled-components";

const getResponsive = (key, value) => {
  return css`
    @media screen and (max-width: 990px) {
      ${key}: calc(100vw * ${value} / 990);
    }

    @media (min-width: 991px) and (max-width: 1920px) {
      ${key}: calc(100vw * ${value} / 1920);
    }

    @media (min-width: 1920px) {
      ${key}: ${value}px;
    }
  `;
}

const LogoImage = styled.img`
  ${getResponsive('width', 163)};
`;

const Logo1 = styled(LogoImage).attrs({
  src: logo1
})`
  ${getResponsive('height', 38)};
`;

const Logo2 = styled(LogoImage).attrs({
  src: logo2
})`
  ${getResponsive('height', 45)};
`;
const Logo3 = styled(LogoImage).attrs({
  src: logo3
})`
  ${getResponsive('height', 56)};
`;
const Logo4 = styled(LogoImage).attrs({
  src: logo4
})`
  ${getResponsive('height', 34)};
`;
const Logo5 = styled(LogoImage).attrs({
  src: logo5
})`
  ${getResponsive('height', 64)};
`;
const Logo6 = styled(LogoImage).attrs({
  src: logo6
})`
  ${getResponsive('height', 46)};
`;
const Logo7 = styled(LogoImage).attrs({
  src: logo7
})`
  ${getResponsive('height', 43)};
`;
const Logo8 = styled(LogoImage).attrs({
  src: logo8
})`
  ${getResponsive('height', 26)};
`;
const Logo9 = styled(LogoImage).attrs({
  src: logo9
})`
  ${getResponsive('height', 43)};
`;
const Logo10 = styled(LogoImage).attrs({
  src: logo10
})`
  ${getResponsive('height', 49)};
`;
const Logo11 = styled(LogoImage).attrs({
  src: logo11
})`
  ${getResponsive('height', 29)};
`;
const Logo12 = styled(LogoImage).attrs({
  src: logo12
})`
  ${getResponsive('height', 47)};
`;
const Logo13 = styled(LogoImage).attrs({
  src: logo13
})`
  ${getResponsive('height', 59)};
`;
const Logo14 = styled(LogoImage).attrs({
  src: logo14
})`
  ${getResponsive('height', 43)};
`;


export default {
  buttons: {
    btnFloating,
    btnArrowLeft,
    btnArrowRight
  },
  logos: [
    Logo1, Logo2, Logo3, Logo4, Logo5, Logo6,
    Logo7, Logo8, Logo9, Logo10, Logo11, Logo12,
    Logo13, Logo14
  ],
  section8Buttons: [
    { link: 'https://bit.ly/3wRFKEw', img: section8Btn1 },
    { link: 'https://bit.ly/3pc5hFX', img: section8Btn2 },
    { link: 'https://bit.ly/3vL7DhA', img: section8Btn3 }
  ],
  section10Buttons: { section10Btn1, section10Btn2 }
};
