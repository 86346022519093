import section1 from './section-1@2x.png';
import section2 from './section-2@2x.png';
import section3 from './section-3@2x.png';
import section4 from './section-4.gif';
import section5 from './section-5@2x.png';
import section6 from './section-6@2x.png';
import section7 from './section-7@2x.png';
import section7Roll1 from './section-7-roll-1@2x.png';
import section7Roll2 from './section-7-roll-2@2x.png';
import section8 from './section-8@2x.png';
import section9 from './section-9@2x.png';
import section9Form from './section-9-form@2x.png';
import section9Circle from './section-9-circle@2x.png';
import section10 from './section-10@2x.png';
import privacyDesc from './privacy-desc@2x.png';
import privacyDescBottom from './privacy-desc-bottom@2x.png';
import btnSubmit1 from './btn-submit-1@2x.png';
import btnSubmit2 from './btn-submit-2@2x.png';
import feeSchedule from './fee-schedule.png'
import styled, { css } from "styled-components";

const getResponsive = (key, value) => {
  return css`
    ${key}: calc(100vw * ${value} / 1920);

    @media screen and (max-width: 990px) {
      ${key}: calc(990px * ${value} / 1920);
    }
    @media screen and (min-width: 1920px) {
      ${key}: ${value}px;
    }
  `;
}

const SliderItem1 = styled.img.attrs({
  src: section7Roll1,
})`
  ${getResponsive('width', 1576)};
  ${getResponsive('height', 947)};
`

const SliderItem2 = styled.img.attrs({
  src: section7Roll2,
})`
  ${getResponsive('width', 1188)};
  ${getResponsive('height', 947)};
`


export default {
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section7SliderItems: [
    SliderItem1,
    SliderItem2
  ],
  section8,
  section9,
  section9Form,
  section9Circle,
  section10,
  btnSubmit1,
  btnSubmit2,
  privacyDesc,
  privacyDescBottom,
  feeSchedule,
};
